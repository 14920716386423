video[poster] {
  object-fit: cover;
}
.vjs-poster {
  background-size: cover;
  background-position: inherit;
}
.video-js .vjs-big-play-button {
  top: auto;
  bottom: 10px;
  border: none;
  width: 1.63332em;
  border-radius: 999px;
  line-height: 1.6;
}
.vjs-error .vjs-error-display:before {
  display: none;
}
.vjs-error .vjs-error-display .vjs-modal-dialog-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
