@import 'var';

.ui-modal-header {
  padding: 25px 30px 10px;
  border-bottom: none;

  h2 {
    font-family: 'Open Sans';
    font-size: 22px;
    color: #444444;
    margin: 0;
    font-weight: 700;
  }
}

.ui-modal-body {
  max-height: calc(100vh - 200px);
  overflow: auto;
  padding: 10px 30px 0;

  p {
    font-family: 'Open Sans';
    font-size: 18px;
    color: #444444;
    font-weight: 400;

    ::ng-deep b {
      font-weight: 600 !important;
    }
  }

  .img-preview {
    width: 140px;
    height: 140px;
    background-size: cover;
    background-color: #fff;
    border-radius: 4px;
    position: relative;

    .img-preview-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      background: rgba(0, 0, 0, 0.35);
      border-radius: 4px;

      &.img-preview-overlay-always-show {
        opacity: 1;
      }

      .img-preview-overlay-icon {
        margin: 0 auto;
        margin-top: 46px;
        display: block;
      }

      input.img-preview-upload {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        max-width: 140px;
      }

      .img-preview-remove {
        position: absolute;
        top: 0;
        right: 0;
        width: 28px;
        height: 28px;
        text-align: center;
        line-height: 34px;

        mdi ::ng-deep svg {
          fill: #ffffff;
        }
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  .suggestions-list {
    height: 250px;
    position: relative;

    &.posts-list {
      ul {
        li {
          min-height: 38px;
          padding: 9px 75px 9px 0px;

          .action {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 65px;
            line-height: 38px;
            text-align: center;
            color: $theme-primary;
          }
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      height: 100%;
      overflow-y: auto;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 2.5px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }

      li {
        display: flex;
        min-height: 58px;
        border-bottom: 1px solid #eceeef;
        font-family: 'Open Sans', sans-serif;
        font-size: 15px;
        line-height: 20px;
        color: #373a3c;
        padding: 19px 75px 19px 55px;
        position: relative;

        &:last-child {
          border-bottom: none;
        }

        .suggestions-list-profile-image {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background: #eee;
          position: absolute;
          top: 13px;
          left: 13px;
          border: none;
        }

        .action {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 65px;
          line-height: 58px;
          text-align: center;
          color: $theme-primary;
          cursor: pointer;
        }

        .disabled-action {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 65px;
          line-height: 58px;
          text-align: center;
          color: #ccc;
        }

        select {
          width: 100px;
          margin-left: 10px;
        }
      }
    }

    &.scroll-at-top:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 4px;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
    }

    &.scroll-at-bottom:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
    }

    .suggestions-list-no-results {
      font-family: 'Open Sans', sans-serif;
      font-size: 21px;
      color: #555555;
      line-height: 250px;
      text-align: center;
    }
  }

  .members-tabs {
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      border-bottom: 1px solid #aaaaaa;

      li {
        display: block;
        width: 50%;
        border-bottom: 4px solid transparent;
        position: relative;
        top: 1px;
        cursor: pointer;

        &.active {
          border-bottom: 4px solid $theme-primary;

          a {
            color: $theme-primary;
          }
        }

        a {
          display: block;
          text-align: center;
          line-height: 40px;

          font-family: 'Open Sans', sans-serif;
          font-size: 16px;
          color: #444444;
        }
      }
    }
  }

  .members-list {
    height: 250px;
    position: relative;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      height: 100%;
      overflow-y: auto;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 2.5px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }

      li {
        min-height: 58px;
        border-bottom: 1px solid #eceeef;
        font-family: 'Open Sans', sans-serif;
        font-size: 15px;
        line-height: 20px;
        color: #373a3c;
        padding: 10px 15px 10px 55px;
        position: relative;
        display: flex;

        &:last-child {
          border-bottom: none;
        }

        &.has-subtitle {
          padding: 0px 15px 0px 55px;

          .members-list-type {
            top: 15px;
          }

          .members-list-remove {
            top: 15px;
          }
        }

        .members-list-profile-image {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background: #eee;
          position: absolute;
          top: 13px;
          left: 13px;
          border: none;
        }

        .members-list-name {
          flex-grow: 1;
          padding: 10px 0;
        }

        .members-list-type {
          width: auto;
          position: relative;
          top: 5px;
        }

        .members-list-remove {
          padding: 8px;
          position: relative;
          left: 15px;

          mdi ::ng-deep svg {
            fill: #555;
          }
        }

        .action {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 65px;
          line-height: 58px;
          text-align: center;
        }

        .disabled-action {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 65px;
          line-height: 58px;
          text-align: center;
          color: #ccc;
        }

        .members-list-subtitle {
          font-family: 'Open Sans', sans-serif;
          font-size: 12px;
          color: #757575;
        }
      }
    }

    &.scroll-at-top:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 4px;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
    }

    &.scroll-at-bottom:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
    }

    .members-list-no-results {
      font-family: 'Open Sans', sans-serif;
      font-size: 21px;
      color: #555555;
      line-height: 250px;
      text-align: center;
    }
  }
}

.ui-modal-footer {
  padding: 10px 30px 22px;
  border-top: none;
  text-align: right;

  .btn {
    margin-right: 6px;
    min-width: 70px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.ui-modal-close {
  padding: 5px;
  position: absolute;
  top: 18px;
  right: 24px;
  height: 32px;
  width: 32px;
  cursor: pointer;

  mdi ::ng-deep svg {
    fill: #444;
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.ui-modal-saving {
  color: transparent !important;
  position: relative;

  &:hover,
  &:active,
  &:visited,
  &:focus,
  &:focus-within {
    color: transparent !important;
  }

  &:after {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -10px;
    margin-top: -10px;
    border-radius: 10px;
    border: 2px solid #fff;
    border-right-color: transparent;
    animation: spinner-border 0.75s linear infinite;
  }
}

@media screen and (max-width: 768px) {
  .ui-modal-header {
    padding: 25px 16px 10px;
  }

  .ui-modal-body {
    padding: 10px 16px 0;
  }

  .ui-modal-footer {
    padding: 10px 16px 22px;
  }

  .ui-modal-close {
    right: 10px;
  }
}
