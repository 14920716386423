.nav-tabs {
  .nav-item {
    a {
      border: none;
      border-bottom: 3px solid transparent;
      color: #888;
      border-radius: 0;

      &:hover {
        border-color: transparent;
        color: #444;

        mdi svg {
          fill: #444;
        }
      }

      &.active {
        color: $theme-primary;
        background-color: transparent;
        border-bottom: 3px solid $theme-primary;

        mdi svg {
          fill: $theme-primary;
        }
      }

      mdi {
        vertical-align: bottom;
        svg {
          fill: #888;
        }
      }
    }
  }
}

.shadow-box-header {
  .nav-tabs {
    margin: -10px;
    border-bottom: 0;

    a {
      height: 60px;
      line-height: 46px;
    }
  }
}

@media screen and (max-width: 576px) {
  .nav-tabs {
    .nav-item {
      a {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
      }
    }
  }
}
