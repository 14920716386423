.sign-up {
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  width: 100vw;
  background: white;

  .form-container {
    margin: 0 auto;
    padding: 20px 30px 80px 30px;
    width: 380px;

    overflow-y: auto;
    position: relative;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;

    .inside-form-container {
      max-width: 320px;
      min-height: 440px;
      margin: 0 auto;
      width: 100%;

      img.sign-up-logo {
        display: block;
        max-width: 320px;
        height: 50px;
        margin: 0 auto 20px;
        object-fit: contain;
      }

      .sign-up-tabs {
        display: flex;
        font-size: 15px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        height: 48px;
        border-bottom: 1px solid #e3e3e3;

        &.sign-up-tabs-only {
          border-bottom: none;
        }

        .sign-up-tabs-tab {
          flex: 1;
          border-bottom: 3px solid transparent;
          text-align: center;
          position: relative;
          top: 1px;

          a {
            line-height: 45px;
            display: block;
            color: #777;
          }

          &.sign-up-tabs-tab-active {
            border-bottom-color: #5871e5;

            a {
              color: #5871e5;
            }
          }
        }
      }

      .sign-up-form {
        padding: 28px 0 0 0;

        input:-webkit-autofill {
          -webkit-background-clip: text;
        }

        input.form-control {
          height: 42px;
          border-color: #e3e3e3;
          border-radius: 6px;
        }

        .input-group > .form-control:not(:last-child),
        .input-group > .form-select:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        .mb-3:last-child {
          margin-bottom: 0;
        }

        .reset-password {
          color: #5871e5;
          font-family: 'Open Sans', sans-serif;
          font-size: 14px;
          margin: 8px 0;
          display: inline-block;
        }
      }

      .sign-up-btn {
        width: 100%;
        height: 45px;
        line-height: 33px;
        font-family: 'Open Sans', sans-serif;
        border-radius: 999px;
        font-size: 15px;
      }
    }

    .extra-login {
      font-family: 'Open Sans', sans-serif;
      color: #999;
      text-align: center;
      margin-top: 4px;
      font-size: 14px;

      a.social-link {
        color: inherit;
        text-decoration: underline;
      }
    }

    .terms-policy {
      position: absolute;
      background: white;
      bottom: 0;
      left: 0;
      right: 0;
      height: 40px;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      line-height: 40px;
      color: #999;
      text-align: center;

      a {
        color: inherit;
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .form-container {
      width: 100%;
    }
  }
}

input:-webkit-autofill::first-line {
  font-size: 1rem;
}

.form-submit a {
  margin-bottom: 10px;
}

.auth-info {
  margin-top: 18px;
  margin-bottom: -8px;
  color: #444;
  font-size: 15px;
}

.auth-link {
  color: #5871e5;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  margin: 4px 0;
  display: inline-block;
}
