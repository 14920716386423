@import 'var';

@import '~bootstrap/scss/functions';

@import '~bootstrap/scss/variables';
@import './styles/bootstrap-variables';

@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';

@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/spinners';

@import '~bootstrap/scss/utilities/api';

@import '~ngx-toastr/toastr';

@import '~video.js/dist/video-js.css';

@import './styles/toastr.scss';
@import './styles/layout.scss';
@import './styles/shadow-box.scss';
@import './styles/modal.scss';
@import './styles/nav-tabs.scss';
@import './styles/auth.scss';
@import './styles/main.scss';
@import './styles/video.scss';

@import 'theme';

a {
  text-decoration: none;
}
