.layout-container {
  padding: 0;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  margin: 0 auto;

  .layout-left-col,
  .layout-right-col {
    width: 260px;
    min-width: 260px;
  }

  .layout-right-col {
    position: sticky;
    bottom: 0;
    align-self: flex-end;
    min-height: 100vh;
    padding: 20px 10px 20px 0;

    .layout-right-button {
      margin-bottom: 20px;
    }
  }

  .layout-middle-col {
    padding: 20px 50px 40px;
    flex: 1;
  }

  .layout-content-col {
    max-width: 100%;
    flex: 1;

    .layout-content-col-inner {
      display: flex;
    }
  }

  @media screen and (max-width: 1200px) {
    .layout-middle-col {
      padding: 20px 5% 40px;
    }

    // .layout-right-col {
    //   display: none;
    // }
  }

  @media screen and (max-width: 768px) {
    .layout-middle-col {
      padding: 10px;
    }
  }

  @media screen and (max-width: 1024px) {
    .layout-right-col {
      display: none;
    }
  }
}
