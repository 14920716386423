html {
  scroll-behavior: smooth;
}

body {
  color: #252525;
  background: #f1f3f5;
  overscroll-behavior: none;
}

.dropdown-toggle.no-caret:after {
  content: none;
}

.logo-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  #logo-loading {
    width: 150px;
    margin-bottom: 50px;
    display: block;
  }

  app-loading {
    margin-top: -24px;
  }
}

.basic-theme-font-color {
  color: $theme-primary;
}

.basic-theme-background {
  background: $theme-primary;
}

.basic-theme-border-color {
  border-color: $theme-primary;
}

*:focus {
  outline: none;
}

.form-control:focus {
  box-shadow: none;
}

a {
  color: $theme-primary;
}

button {
  outline: none !important;
}

@keyframes countdownclip {
  from {
    stroke-dashoffset: 150px;
  }
  to {
    stroke-dashoffset: 0px;
  }
}

mdi ::ng-deep svg {
  fill: #444;
}

.active mdi ::ng-deep svg {
  fill: #fff;
}

.alert a {
  font-weight: bold;
  text-decoration: underline;
}

angular-mydatepicker {
  display: inline-flex;
}

.tiles {
  display: grid;
  grid-gap: 18px 20px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

.cards {
  display: grid;
  grid-gap: 18px 20px;
  grid-template-columns: repeat(auto-fill, 220px);
}

@media screen and (max-width: 768px) {
  .tiles {
    grid-gap: 12px 20px;
  }

  .table-container {
    overflow-x: auto;
  }
}

.loading {
  background: rgba(0, 0, 0, 0.7);
  width: 80px;
  height: 80px;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  z-index: 9999;
  border-radius: 10px;

  &:hover {
    color: transparent;
  }

  &:after {
    content: '';
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
    border-radius: 20px;
    border: 4px solid #fff;
    border-right-color: transparent;
    animation: spinner-border 0.75s linear infinite;
  }
}

a.nav-link {
  cursor: pointer;
}

.light-tooltip {
  opacity: 1 !important;

  .tooltip-inner {
    background-color: #eee;
    color: #222;
  }

  .tooltip-arrow::before {
    border-top-color: #eee;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #f8f9fa;
  color: inherit;
}

.timeline-load-more {
  max-width: 720px;
}

.load-more {
  max-width: 720px;
  margin: 0 auto 0 0;
}

.record-container {
  display: flex;
  justify-content: center;
}

.input-group {
  .btn-light {
    border-color: #ced4da;
  }
}

.button-action-main {
  font-size: 16px;
  margin-bottom: 12px;

  > div {
    cursor: pointer;
    color: #007bff;
  }

  mdi {
    position: relative;
    top: -2px;

    ::ng-deep svg {
      fill: #007bff;
    }

    svg {
      fill: #007bff;
    }
  }
}

.fc-hover {
  background: lighten(#007bff, 40%);
  position: absolute;
  left: 1px;
  top: 0;
  right: 1px;
  border-radius: 3px;
  height: 15px;
  border: 1px solid lighten(#007bff, 10%);
}

.fc-hover-30 {
  height: 30px;
}

.fc-hover-45 {
  height: 45px;
}

.fc-hover-60 {
  height: 60px;
}

.fc-timegrid-slot {
  height: 14px !important;
  line-height: 14px;
}

.fc-timegrid-slot-minor {
  border-top-color: transparent !important;
}

.appointments-info {
  margin-bottom: 3px;

  mdi {
    position: relative;
    top: -2px;
  }
}

:root {
  --x: 250px;
}

@media screen and (max-width: 768px) {
  :root {
    --x: 285px;
  }
}
