.shadow-box {
  padding: 0;
  background: #ffffff;
  margin-bottom: 1rem;
  border-radius: 10px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);

  .shadow-box-header {
    padding: 10px 30px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;

    .shadow-box-header-left {
      flex: 1;
      display: flex;

      .form-control {
        max-width: 240px;
        width: 240px;
        margin-right: 12px;
      }
    }
  }

  .shadow-box-body {
    padding: 15px 25px;

    .table {
      margin: -25px;
      width: calc(100% + 50px);

      th,
      td {
        border-top: 1px solid #eee;
      }

      thead th {
        border-bottom: 1px solid #eee;
        border-top: 0;
        font-size: 14px;
        color: #2a2c2e;

        &:first-child {
          padding-left: 24px;
        }
      }

      td {
        color: #373a3c;
        font-size: 15px;
        vertical-align: middle;

        > a {
          text-decoration: underline;
          color: inherit;

          &.action {
            color: $theme-primary;
            text-decoration: none;
          }

          span {
            color: inherit;
          }
        }

        &:first-child {
          padding-left: 24px;
        }

        span {
          color: #373a3c;
        }
      }
    }
  }

  .shadow-box-footer {
    padding: 10px 30px;
    border-top: 1px solid #eee;
  }

  .top {
    display: flex;
    justify-content: space-between;

    .search-box {
      display: flex;
      margin: 0;

      .search-icon {
        position: relative;
        top: 5px;
        left: -2px;
      }

      .search-input {
        position: relative;
        width: 300px;

        span {
          position: absolute;
          font-size: 1.3em;
          line-height: 2em;
          left: 0.5em;
          color: #444;
        }

        input {
          padding-left: 35px;
        }
      }
    }
  }

  .video-link {
    display: block !important;
    text-align: center;
    cursor: pointer;
    svg {
      margin-top: 3px;
    }
  }

  .play-icon mdi {
    position: relative;
    top: -1px;
  }

  .play-icon mdi[name='Stop'] {
    position: relative;
    top: -2px;
  }

  .icon {
    display: inline-block;
    margin-right: 7px;
    width: 20px;
    height: 20px;
    vertical-align: text-bottom;
    position: relative;
    top: 1px;

    img {
      max-width: 100%;
    }
  }
}

// @media screen and (max-width: 768px) {
//   .shadow-box {
//     border-radius: 0;
//   }
// }

.custom-date-form {
  position: absolute;
  background: white;
  padding: 16px 20px;
  text-align: left;
  border-radius: 4px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid #eee;
  right: 0;
  z-index: 1;

  label {
    margin-bottom: 0;
  }

  .input-group {
    margin-bottom: 8px;
  }
}

.data-range-container {
  position: relative;

  button {
    white-space: nowrap;
  }
}
